import {
  BackgroundProvider,
  Header,
  Input,
  Text,
  Flex,
  Button,
} from "components";
import { useEffect, useState } from "react";
import Zendesk from "react-zendesk";
import { COUPONS } from "api";
import { toast } from "react-toastify";

export default () => {
  const [coupon, setCoupon] = useState("");

  // Get coupon from url
  useEffect(() => {
    const coupon = window.location.pathname.split("/").pop();
    setCoupon(coupon);
  }, []);

  const claimCoupon = async () => {
    try {
      await COUPONS.createCredit({ coupon });
      toast.success("Coupon claimed successfully!");
      setTimeout(() => {
        window.location.href = "/welcome";
      }, 2000);
    } catch (err) {
      if (err.response?.data?.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error("Error claiming coupon");
      }
    }
  };

  return (
    <>
      <BackgroundProvider align="flex-start" minHeight="100vh">
        <Zendesk zendeskKey={"2a2352ea-85dc-4661-9d28-bc3ce436ae6c"} />
        <Header user showBackButton={false} />
        <Flex margin="89px 0 0">
          <Flex
            width="1500px"
            margin="0 auto"
            padding="60px 40px"
            direction="column"
            align="flex-start"
            justify="flex-start"
          >
            <Flex margin="24px 0" justify="space-between" align="flex-start">
              <div>
                <Text
                  size="64px"
                  weight="500"
                  margin="0 0 17px"
                  className="canela"
                >
                  Claim Coupon
                </Text>
                <div>
                  <Input
                    margin="0 0 12px"
                    style={{ fontSize: "24px", width: "320px" }}
                    placeholder="Enter coupon code"
                    value={coupon}
                    onChange={(e) => setCoupon(e.target.value)}
                    disabled
                  />
                </div>
                <Button width="320px" className="large" onClick={claimCoupon}>
                  <Text size="18px" weight="700">
                    Claim
                  </Text>
                </Button>
              </div>
            </Flex>
          </Flex>
        </Flex>
      </BackgroundProvider>
    </>
  );
};
