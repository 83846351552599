import React, { useEffect, useState } from "react";
import { useAnalytics } from "use-analytics";
import {
  Button,
  Header,
  BackgroundProvider,
  Flex,
  Box,
  Input,
  Label,
  Text,
  TosCheckbox,
  Select,
} from "components";
import { USERS } from "api";
import { validate, stripeCountries } from "utils";
import { colors } from "consts";
import { useLocation, useHistory } from "react-router-dom";

export default () => {
  const [message, setMessage] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [tosAccepted, setTosAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState({
    value: "US",
    name: "United States",
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [businessType, setBusinessType] = useState({
    value: "individual",
    name: "Individual",
  });
  const { page } = useAnalytics();
  const history = useHistory();

  useEffect(() => {
    page();
  }, [page]);

  const search = useLocation().search;

  let prefill = {};
  try {
    const data = new URLSearchParams(search).get("data");
    if (data) prefill = JSON.parse(atob(data));
  } catch (e) {
    console.log("Failed to parse data param", e);
  }

  useEffect(() => {
    if (prefill.name) {
      const name = prefill.name.split(" ");
      setFirstName(name.shift());
      setLastName(name.join(" "));
    }
    if (prefill.email) setEmail(prefill.email);
  }, [prefill.name, prefill.email]);

  const businessTypes = [
    {
      value: "individual",
      name: "Individual",
    },
    {
      value: "company",
      name: "Business",
    },
  ];

  const queryPromo = window.location.search.match(/promo_code=(\w+)/i);
  const [promoCode, setPromoCode] = useState(queryPromo ? queryPromo[1] : "");

  const toggleTos = () => setTosAccepted(!tosAccepted);

  const register = async () => {
    if (!firstName || !lastName) {
      setMessage("Please enter a valid name");
    } else if (!email) {
      setMessage("Please enter a valid email");
    } else if (!password || password.length < 8) {
      setMessage("Please enter a password with at least 8 characters");
    } else if (password !== confirmPassword) {
      setMessage("Passwords do not match");
    } else if (!tosAccepted) {
      setMessage("Please accept terms of services");
    } else if (
      !validate("number", phoneNumber.replace(/-/g, "")) ||
      phoneNumber.replace(/-/g, "").length < 8 ||
      !phoneNumber
    ) {
      setMessage("Please enter a valid phone number");
    } else if (businessType?.value === "company" && !companyName) {
      setMessage("Please provide a company name");
    } else {
      try {
        setMessage(null);
        setLoading(true);

        const _created = await USERS.create({
          type: "filmmaker",
          firstName,
          lastName,
          email,
          promoCode,
          country: country.value,
          password,
          tos: tosAccepted,
          phoneNumber: phoneNumber.replace(/-/g, ""),
          businessType: businessType.value,
          ...(companyName && { companyName }),
        });

        if (_created.data) {
          setMessage(undefined);
          await USERS.login({ email, password });
          setLoading(false);

          history.push("/next-steps");
        }
      } catch (err) {
        setMessage("Error creating account");
      }
    }
  };

  return (
    <BackgroundProvider url={require("assets/images/projector.jpg").default}>
      <Header showBackButton={false} />

      <Flex margin="89px 0 0" justify="center">
        <Box width="540px" margin="48px 0" light>
          <Flex direction="column" align="flex-start">
            <Text dark size="24px" lineheight="36px" margin="0 0 45px">
              Register for an account and setup your virtual screening today.
            </Text>

            <Label color={colors.black}>First Name</Label>
            <Input
              margin="0 0 30px"
              light
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First Name"
            />

            <Label color={colors.black}>Last Name</Label>
            <Input
              margin="0 0 30px"
              light
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Last Name"
            />

            <Label color={colors.black}>Email</Label>
            <Input
              margin="0 0 30px"
              light
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="email"
            />

            <Label color={colors.black}>Phone Number</Label>
            <Input
              margin="0 0 30px"
              light
              type="tel"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Phone Number"
            />

            <Label color={colors.black}>Country</Label>
            <Select
              light
              margin="0 0 15px"
              selected={country}
              options={stripeCountries}
              setSelected={setCountry}
            />

            <Label color={colors.black}>Business Type</Label>
            <Select
              light
              margin="0 0 5px"
              selected={businessType}
              options={businessTypes}
              setSelected={setBusinessType}
            />

            <Text size="14px" margin="0 0 24px" color={colors.gray8}>
              Would you like to report any funds generated through events as an
              Individual (SSN) or Business (EIN)? Note that this will only apply
              if you meet your country’s tax requirements. For non US-based
              filmmakers, foreign equivalents will apply.{" "}
              <a
                href="https://support.showandtell.film/hc/en-us/articles/360055892231-Show-Tell-Taxes"
                target="_blank"
                rel="noopener noreferrer"
              >
                More Info
              </a>
              .
            </Text>

            {businessType?.value === "company" && (
              <>
                <Label color={colors.black}>Business Name</Label>
                <Input
                  margin="0 0 5px"
                  light
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  placeholder="Company Name"
                />
                <Text size="14px" margin="0 0 24px" color={colors.gray8}>
                  Please enter the legal name of your business here.
                </Text>
              </>
            )}

            <Label color={colors.black}>Password</Label>
            <Input
              margin="0 0 30px"
              light
              value={password}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="password"
            />

            <Label color={colors.black}>Confirm Password</Label>
            <Input
              margin="0 0 30px"
              light
              value={confirmPassword}
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="password"
            />

            <Label color={colors.black}>Promo code</Label>
            <Input
              margin="0 0 30px"
              light
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
              placeholder="If you have a promo code, put it in here."
            />

            <TosCheckbox
              tosAccepted={tosAccepted}
              setTosAccepted={setTosAccepted}
              dark
              toggleTos={toggleTos}
              margin="0 0 24px"
            />

            <Flex justify="center">
              {message && (
                <Text margin="-32px 0 12px" color={colors.red}>
                  {message}
                </Text>
              )}
            </Flex>

            <Flex justify="flex-end">
              {loading ? (
                <Button bold>Registering...</Button>
              ) : (
                <Button
                  bold
                  className={`${!tosAccepted ? "disabled" : ""}`}
                  onClick={() => tosAccepted && register()}
                >
                  Register
                </Button>
              )}
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </BackgroundProvider>
  );
};
